import { useEffect } from 'react';
import Swal from 'sweetalert2';

function AdBlockDetection() {
    
    useEffect(() => {
        try {
            const item = sessionStorage.getItem("Checked-AdBlock")
            const elementHeight = document.getElementById('ad').clientHeight;
            if(item) {
                // AdBlock already checked for
            } else {
                // Check for AdBlock
                if(elementHeight > 0) {
                    // No AdBlock Detected
                } else {
                    // AdBlock Detected
                    Swal.fire({
                        title: 'AdBlock Detected!',
                        html: "This website is made possible by displaying ads to our visitors. Please support us by whitelisting the website.",
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: "Okay, I'll whitelist"
                    }).then((result) => {
                        if (result.value) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Thank You',
                                text: 'We appreciate your cooperation!',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        }
                    })
                }
                sessionStorage.setItem("Checked-AdBlock", "true");
            }
        } catch (error) {
            console.error(error);
        }
    }, [])

    return null;
}

export default AdBlockDetection;