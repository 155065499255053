import React from "react";
import { Link } from "gatsby"
import { CaretDown } from 'react-bootstrap-icons';

import * as styles from '../../styles/flex/navbar.module.css';

function Navbar() {
    return (
        <nav className={styles.NavBar}>
            <div className={styles.Container}>
                <input type='checkbox' className={styles.Check} id='check'></input>
                
                <div className={styles.LogoContainer}>
                    <h3 className={styles.Logo}>GTA<span> Max Profit</span></h3>
                </div>

                <div className={styles.NavBtn}>
                    <div className={styles.NavLinks}>
                        <ul>
                            <li className={styles.NavLink}>
                                <Link to='/'>Home</Link>
                            </li>

                            <li className={styles.NavLink}>
                                <Link to='#'>Calculators<CaretDown className={styles.CaretDownIcon}/></Link>
                                <div className={styles.Dropdown}>
                                    <ul>
                                        {/* <li className={styles.DropdownLink}>
                                            <Link to='/profit-calculators/gta-online-arcade-profit-calculator'>Arcade</Link>
                                        </li> */}

                                        {/* <li className={styles.DropdownLink}>
                                            <Link to='/profit-calculators/gta-online-nightclub-profit-calculator'>Nightclub</Link>
                                        </li> */}

                                        <li className={styles.DropdownLink}>
                                            <Link to='/profit-calculators/gta-online-bunker-profit-calculator'>Bunker</Link>
                                        </li>

                                        <li className={styles.DropdownLink}>
                                            <Link to='#'>Bikers<CaretDown className={styles.CaretDownIcon}/></Link>
                                            <div className={[styles.Dropdown, styles.Second].join(' ')}>
                                                <ul>
                                                    <li className={styles.DropdownLink}>
                                                        <Link to='/profit-calculators/gta-online-cocaine-lockup-profit-calculator'>Cocaine Lockup</Link>
                                                    </li>

                                                    <li className={styles.DropdownLink}>
                                                        <Link to='/profit-calculators/gta-online-document-forgery-profit-calculator'>Document Forgery</Link>
                                                    </li>

                                                    <li className={styles.DropdownLink}>
                                                        <Link to='/profit-calculators/gta-online-counterfeit-cash-profit-calculator'>Counterfeit Cash</Link>
                                                    </li>

                                                    <li className={styles.DropdownLink}>
                                                        <Link to='/profit-calculators/gta-online-weed-farm-profit-calculator'>Weed Farm</Link>
                                                    </li>

                                                    <li className={styles.DropdownLink}>
                                                        <Link to='/profit-calculators/gta-online-meth-lab-profit-calculator'>Meth Lab</Link>
                                                    </li>

                                                    <div className={styles.Arrow}></div>
                                                </ul>
                                            </div>
                                        </li>

                                        <div className={styles.Arrow}></div>
                                    </ul>
                                </div>
                            </li>

                            <li className={styles.NavLink}>
                                <Link to='#'>More<CaretDown className={styles.CaretDownIcon}/></Link>
                                <div className={styles.Dropdown}>
                                    <ul>
                                        <li className={styles.DropdownLink}>
                                            <Link to='/more/profit-tables'>Profit Tables<CaretDown className={styles.CaretDownIcon}/></Link>
                                            <div className={[styles.Dropdown, styles.Second].join(' ')}>
                                                <ul>
                                                    <li className={styles.DropdownLink}>
                                                        <Link to='/more/profit-tables/#document-forgery'>Document Forgery</Link>
                                                    </li>

                                                    <li className={styles.DropdownLink}>
                                                        <Link to='/more/profit-tables/#weed-farm'>Weed Farm</Link>
                                                    </li>

                                                    <li className={styles.DropdownLink}>
                                                        <Link to='/more/profit-tables/#counterfeit-cash'>Counterfeit Cash</Link>
                                                    </li>

                                                    <li className={styles.DropdownLink}>
                                                        <Link to='/more/profit-tables/#meth-lab'>Meth Lab</Link>
                                                    </li>

                                                    <li className={styles.DropdownLink}>
                                                        <Link to='/more/profit-tables/#cocaine-lockup'>Cocaine Lockup</Link>
                                                    </li>

                                                    <li className={styles.DropdownLink}>
                                                        <Link to='/more/profit-tables/#bunker'>Bunker</Link>
                                                    </li>

                                                    <li className={styles.DropdownLink}>
                                                        <Link to='/more/profit-tables/#nightclub'>Nightclub</Link>
                                                    </li>

                                                    <div className={styles.Arrow}></div>
                                                </ul>
                                            </div>
                                        </li>
                                        <div className={styles.Arrow}></div>
                                    </ul>
                                </div>
                            </li>

                            <li className={styles.NavLink}>
                                <Link to='#'>Feedback<CaretDown className={styles.CaretDownIcon}/></Link>
                                <div className={styles.Dropdown}>
                                    <ul>
                                        {/* <li className={styles.DropdownLink}>
                                            <Link to='/feedback/feedback-form'>Feedback Form</Link>
                                        </li> */}

                                        <li className={styles.DropdownLink}>
                                            <Link to='/feedback/contact-form'>Contact Us</Link>
                                        </li>

                                        <li className={styles.DropdownLink}>
                                            <Link to='/more/road-map'>Road Map</Link>
                                        </li>

                                        <div className={styles.Arrow}></div>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.LogSign}>
                        {/* <Link to='#' className={styles.BtnTransparent}>Log in</Link>
                        <Link to='#' className={styles.BtnSolid}>Sign up</Link> */}
                    </div>
                </div>
            
                <div className={styles.HamburgerMenuContainer}>
                    <div className={styles.HamburgerMenu}>
                        <div></div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;
