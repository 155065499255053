// extracted by mini-css-extract-plugin
export var NavBar = "navbar-module--NavBar--1BvIL";
export var Container = "navbar-module--Container--38q3O";
export var NavBtn = "navbar-module--NavBtn--2mm8Z";
export var NavLinks = "navbar-module--NavLinks--1hk9C";
export var LogSign = "navbar-module--LogSign--1YFHF";
export var LogoContainer = "navbar-module--LogoContainer--2oHRN";
export var Logo = "navbar-module--Logo--IviGL";
export var GemIcon = "navbar-module--GemIcon--1h_XN";
export var BtnTransparent = "navbar-module--BtnTransparent--2PcLJ";
export var BtnSolid = "navbar-module--BtnSolid--2b_K2";
export var CaretDownIcon = "navbar-module--CaretDownIcon--3D0Zh";
export var NavLink = "navbar-module--NavLink--1L1wg";
export var Dropdown = "navbar-module--Dropdown--1_icb";
export var DropdownLink = "navbar-module--DropdownLink--1-ObM";
export var Arrow = "navbar-module--Arrow--1FeAk";
export var Second = "navbar-module--Second--_P0YE";
export var HamburgerMenuContainer = "navbar-module--HamburgerMenuContainer--1Oh3R";
export var HamburgerMenu = "navbar-module--HamburgerMenu--1evVb";
export var Check = "navbar-module--Check--McAVm";
export var animation = "navbar-module--animation--3mBOM";