/**
 * Layout component that queries  data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { useEffect } from "react";

import Header from "./header";
import Footer from "./flex/footer";
import AdBlockDetection from '../scripts/adBlockDetection.js';

import "../styles/layout.css";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  
  // AdBlockDetection();
  
  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`}></Header>
      <div style={{ margin: `0 auto`, /*padding: `0 1.0875rem 1.45rem`*/ }}>
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;