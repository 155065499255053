import * as React from "react";
import PropTypes from "prop-types";
import Navbar from "./flex/navbar";

import * as styles from '../styles/flex/header.module.css';

function Header() {
  return (
    <header className={styles.Header}>
      <Navbar/>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
